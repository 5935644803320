import Api from '@/axios'

export default {
  getProductDetails (id) {
    return Api().get(`pages/categories/item/${id}`)
  },
  addToCart (id, payload) {
    return Api().post(`users/carts/${id}`, payload)
  },
  buyNow (id, payload) {
    const getComeFrom = sessionStorage.getItem('come_from') || 'web'
    return Api().post(`users/orders/buy-now/${id}`, { ...payload, come_from: getComeFrom })
  },
  guestOrder (payload) {
    const getComeFrom = sessionStorage.getItem('come_from') || 'web'
    return Api().post('guest-order', { ...payload, come_from: getComeFrom })
  }
}
